import React, { useEffect } from "react";
const imagem = ["/img/crew1.jpg"];
const logo = ["/img/crew-bar.jpg"];
const texto = [
  "Como as melhores historias são sempre as mais antigas, esta não podia deixar de ser diferente. Em 1992 abrimos as portas pela primeira vez, a amigos, conhecidos e visitantes (all aboard), sempre com o espírito dos mares bem vincado, pois acreditamos que um espaço só se faz se for inundado com memórias.",
  "A fusão entre ideias clássicas e contemporâneas deu origem a esta nova etapa, para quem nos visita notará que a renovação não só foi feita no espaço, mas também na carta de bar e cozinha, com uma aposta nos produtos locais, tradicionais e sempre frescos.",
];

const About = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div id="about">
      <div className="container">
        <div className="about-title text-center">
          <h2>A nossa história</h2>
          <img
            src={logo.slice(0, 1).join(" ")}
            className="img-responsive about-logo"
            alt="Crew-Bar"
          />
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 ">
            <div className="about-img">
              <img
                src={imagem.slice(0, 1).join(" ")}
                className="img-responsive"
                alt=""
              />
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <p>{texto.slice(0, 1).join(" ")}</p>
              <p>{texto.slice(1, 2).join(" ")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
