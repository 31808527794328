import React from "react";
import {Link} from 'react-router-dom';

var year = new Date().getFullYear();

const Footer = () => {
  return (
    <div id="footer">
    <div className="container text-center">
      <div className="col-md-4">
        <h3>Morada</h3>
        <div className="contact-item">
          <p>Pct Gil Vicente 80 Loja D</p>
          <p>2765-484 São João do Estoril</p>
          <p>Cascais, Lisboa, Portugal</p>
        </div>
      </div>
      <div className="col-md-4">
        <h3>Horário</h3>
        <div className="contact-item">
          <p>Terça-Sábado: 16:00 - 04:00</p>
          <p>Domingo: 16:00 - 24:00</p>
          <p>Encerra à Segunda</p>
        </div>
      </div>
      <div className="col-md-4">
        <h3>Contato</h3>
        <div className="contact-item">
          <p>Telemóvel: 917 388 630</p>
          <p>Email: crewbar@gmail.com</p>
        </div>
      </div>
    </div>
    <div id="map">
      <iframe title="mapa" width="100%" height="400"
      src="https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=Praceta+Gil+Vicente,+nº+80+loja+D,+2765-484+São+João+do+Estori&sll=38.6996167,-9.3837651,17z&4&output=embed">
      </iframe>
    </div>
    <div className="container-fluid text-center copyrights">
      <div className="col-md-8 col-md-offset-2">
        <div className="social">
          <ul>
            <li><a href="https://www.facebook.com/thecrewbar2018" target="_blank" rel="noreferrer"><i className="fa fa-facebook"></i></a></li>
            <li><a href="https://www.instagram.com/barthecrew" target="_blank" rel="noreferrer"><i className="fa fa-instagram"></i></a></li>
          </ul>
        </div>
        <p><Link to="policy">Politica de Privacidade e Cookies</Link></p>
        <p>&copy; {year} Crew-Bar. Adaptado por <a href="http://www.resolution.pt" rel="nofollow noreferrer" target="_blank">Resolution - Consultores Informáticos, Lda.</a></p>
      </div>
    </div>
  </div>
  );
};

export default Footer;
