import React from "react";

const Hero = () => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="intro-text">
                <h1>Crew-Bar</h1>
                <p>Pub / Bar de Vinhos / Restaurante</p>
                <a href="#about" className="btn btn-custom btn-lg page-scroll">
                  Descubra Crew-Bar
                </a>{" "}
                <br /><br />
               <p className="construct">* Site Demonstração *</p>
               <p><span style={{ color: 'red', fontSize: '32px'}}>Atenção!</span><br /> Não é o Site Oficial do Crew-Bar</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hero;
