import * as React from "react";
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import "./styles/hamburger.scss";
import "./styles/carousel.css"; // change this to the file path of your overrides
import "./styles/imports.scss";
import "./styles/bootstrap.min.css";
// import "./styles/rci.css";
import "./styles/style.css";

import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./pages/About";
import Landing from "./pages/Landing";
import Ementa from "./pages/Ementa";
import Bebidas from "./pages/Bebidas";
import Vinhos from "./pages/Vinhos";
import Policy from "./pages/Policy";

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="about" element={<About />} />
        <Route path="ementa" element={<Ementa />} />
        <Route path="bebidas" element={<Bebidas />} />
        <Route path="vinhos" element={<Vinhos />} />
        <Route path="policy" element={<Policy />} />
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;


