import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import Ementa from "../files/Ementa.json";

const capa = [" ", " ", " ", " ", " ", " ", " ", " ", "Comidas"];

var year = new Date().getFullYear();
var mm = new Date().getMonth() + 1; // getMonth() is zero-based
var dd = new Date().getDate();

var data = [year, (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("");

const Menu = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <div id="restaurant-menu">
      <div className="overlay">
        <div className="section-title text-center center">
          <div className="hidden-xl hidden-lg hidden-md">
            <h2>Menu</h2>
            <hr></hr>
          </div>
          <p>Preços sujeitos a confirmação no estabelecimento</p>
        </div>

        <div id="carousel" className="carousel">
          <Carousel showArrows showThumbs={false} infiniteLoop emulateTouch>
            <div>
              <img src="img/crew-base.jpg" alt="comidas" />
              <div className="legend">
                <RenderCapa />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="comidas" />
              <div className="legend">
                <RenderGroup titulo={"PARA PARTILHAR"} grupo={"Partilha"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="comidas" />
              <div className="legend">
                <RenderGroup titulo={"SANDUÍCHES"} grupo={"Sanduiches"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="comidas" />
              <div className="legend">
                <RenderGroup titulo={"ESPECIAIS"} grupo={"Especiais"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="comidas" />
              <div className="legend">
                <RenderDuplo
                  titulo1={"SALGADOS"}
                  grupo1={"Salgados"}
                  titulo2={"SALADAS"}
                  grupo2={"Saladas"}
                />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="comidas" />
              <div className="legend">
                <RenderDuplo
                  titulo1={"ACOMPANHAMENTOS"}
                  grupo1={"Acompanhamentos"}
                  titulo2={"SOBREMESAS"}
                  grupo2={"Sobremesas"}
                />
              </div>
            </div>
          </Carousel>
        </div>
        <span className="visible-xs">
          <br />
          <br />
        </span>

        <span className="hidden-xs">
          {/* <div className="collapse_section">
             <button type="button" className="rci-button rci-toggle collapsed" data-toggle="collapse" data-target="#lista_c">
                Ver em lista&nbsp;&nbsp;
                <i className="glyphicon"></i>
              </button>
              <br /><br />
           </div> */}
          <div id="lista_c" className="hidden-sm hidden-xs">
            <div className="section-title text-center center">
              <h2>Lista</h2>
              <hr></hr>
            </div>
            <RenderEmLista />
          </div>
        </span>
      </div>
    </div>
  );
};
export default Menu;

const RenderCapa = () => {
  return (
    <div className="list_view">
      <div className="section-title text-center center">
        <div className="menu-section">
          {capa.map((value, index) => {
            if (value !== " ") {
              return (
                <div key={index} className="menu-item">
                  <div className="menu-item-cover text-center">{value}</div>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <br />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

const RenderMenu = ({ titulo, grupo }) => {
  return (
    <div className="menu-section">
      <h2 className="menu-section-title">{titulo}</h2>
      {Ementa.map(function (item, i) {
        if (
          item.status === "Active" &&
          item.group === grupo &&
          data >= item.valini &&
          data <= item.valend
        ) {
          return (
            <div key={i} className="menu-item">
              <div className="menu-item-name">{item.title}</div>
              <div className="menu-item-price">{item.price}</div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

const RenderGroup = ({ titulo, grupo }) => {
  return (
    <div className="list_view">
      <div className="section-title text-center center">
        <div className="menu-section">
          <h2 className="menu-section-title">{titulo}</h2>
          {Ementa.map(function (item, i) {
            if (
              item.status === "Active" &&
              item.group === grupo &&
              data >= item.valini &&
              data <= item.valend
            ) {
              return (
                <div key={i} className="menu-item">
                  <div className="menu-item-name">{item.title}</div>
                  <div className="menu-item-price">{item.price}</div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

const RenderDuplo = ({ titulo1, grupo1, titulo2, grupo2 }) => {
  return (
    <div className="list_view">
      <div className="section-title text-center center">
        <div className="menu-section">
          <h2 className="menu-section-title">{titulo1}</h2>
          {Ementa.map(function (item, i) {
            if (
              item.status === "Active" &&
              item.group === grupo1 &&
              data >= item.valini &&
              data <= item.valend
            ) {
              return (
                <div key={grupo1 + i} className="menu-item">
                  <div className="menu-item-name">{item.title}</div>
                  <div className="menu-item-price">{item.price}</div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>

        <div className="menu-section">
          <h2 className="menu-section-title">{titulo2}</h2>

          {Ementa.map(function (item, i) {
            if (
              item.status === "Active" &&
              item.group === grupo2 &&
              data >= item.valini &&
              data <= item.valend
            ) {
              return (
                <div key={grupo2 + i} className="menu-item">
                  <div className="menu-item-name">{item.title}</div>
                  <div className="menu-item-price">{item.price}</div>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

const RenderEmLista = () => {
  return (
    <div className="container">
      <div className="list_view">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <RenderMenu titulo={"PARA PARTILHAR"} grupo={"Partilha"} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <RenderMenu titulo={"SANDUÍCHES"} grupo={"Sanduiches"} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-3"></div>
          <div className="col-xs-12 col-sm-6">
            <RenderMenu titulo={"ESPECIAIS"} grupo={"Especiais"} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <RenderMenu titulo={"SALGADOS"} grupo={"Salgados"} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <RenderMenu titulo={"SALADAS"} grupo={"Saladas"} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <RenderMenu titulo={"ACOMPANHAMENTOS"} grupo={"Acompanhamentos"} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <RenderMenu titulo={"SOBREMESAS"} grupo={"Sobremesas"} />
          </div>
        </div>
      </div>
    </div>
  );
};
