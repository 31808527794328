import React, {useEffect} from 'react';
import Hero from "../components/Hero";
import About from "./About";

const Landing = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);
  return (
    <div>
      <Hero />
      <About />
    </div>
  )
}

export default Landing;
