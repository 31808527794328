import React from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "./Hamburger";

const Header = () => {
  return (
    <div>
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="flex hidden-md hidden-lg hidden-xl">
          <HamburgerMenu />
        </div>
        <div className="container">
          <div className="navbar-header page-scroll">
            <Link to="/">
              <span className="navbar-brand page-scroll">Crew Bar *Demo*</span>
            </Link>
          </div>

          <div className="hidden-sm hidden-xs">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="about">Quem Somos</Link>
              </li>
              <li>
                <Link to="ementa">Menu</Link>
              </li>
              <li>
                <Link to="bebidas">Bebidas</Link>
              </li>
              <li>
                <Link to="vinhos">Vinhos</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
