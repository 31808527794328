import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import Drinks from "../files/Bebidas.json";

const capa = [" ", " ", " ", " ", " ", " ", " ", " ", "Bebidas"];

var year = new Date().getFullYear();
var mm = new Date().getMonth() + 1; // getMonth() is zero-based
var dd = new Date().getDate();

var data = [year, (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("");

const Bebidas = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <div id="bebidas">
      <div className="overlay">
        <div className="section-title text-center center">
          <div className="hidden-xl hidden-lg hidden-md">
            <h2>Bebidas</h2>
            <hr></hr>
          </div>
          <p>Bebidas e preços sujeitos a confirmação no estabelecimento</p>
        </div>

        <div id="carousel" className="carousel">
          <Carousel showArrows showThumbs={false} infiniteLoop emulateTouch>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderCapa />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"CAFETARIA"} grupo={"Cafetaria"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"REFRIGERANTES"} grupo={"Refrigerantes"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"CERVEJA"} grupo={"Cerveja"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"SHOTS"} grupo={"Shots"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"VINHOS"} grupo={"Vinhos"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"WHISKYS"} grupo={"Whiskys"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup
                  titulo={"BEBIDAS COMPOSTAS"}
                  grupo={"BebidasCompostas"}
                />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"APERITIVOS"} grupo={"Aperitivos"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"DIGESTIVOS"} grupo={"Digestivos"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"LICORES"} grupo={"Licores"} />
              </div>
            </div>
            <div>
              <img src="img/crew-base.jpg" alt="bebidas" />
              <div className="legend">
                <RenderGroup titulo={"COCKTAILS"} grupo={"Cocktails"} />
              </div>
            </div>
          </Carousel>
        </div>
        <span className="visible-xs">
          <br />
          <br />
        </span>

        <span className="hidden-xs">
          {/* <div className="collapse_section">
            <button
              type="button"
              className="rci-button rci-toggle collapsed"
              data-toggle="collapse"
              data-target="#lista_b"
            >
              Ver em lista&nbsp;&nbsp;
              <i className="glyphicon"></i>
            </button>
            <br />
            <br />
          </div> */}

          <div id="lista_b" className="hidden-sm hidden-xs">
            <div className="section-title text-center center">
              <h2>Lista</h2>
              <hr></hr>
            </div>
            <div className="container">
              <div className="list_view">
                <div className="col-xs-12 col-sm-6">
                  <div className="row">
                    <RenderMenu titulo={"CAFETARIA"} grupo={"Cafetaria"} />
                    <RenderMenu
                      titulo={"REFRIGERANTES"}
                      grupo={"Refrigerantes"}
                    />
                    <RenderMenu titulo={"CERVEJA"} grupo={"Cerveja"} />
                    <RenderMenu titulo={"SHOTS"} grupo={"Shots"} />
                    <RenderMenu titulo={"VINHOS"} grupo={"Vinhos"} />
                    <RenderMenu titulo={"WHISKYS"} grupo={"Whiskys"} />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="row">
                    <RenderMenu
                      titulo={"BEBIDAS COMPOSTA"}
                      grupo={"BebidasCompostas"}
                    />
                    <RenderMenu titulo={"APERITIVOS"} grupo={"Aperitivos"} />
                    <RenderMenu titulo={"DIGESTIVOS"} grupo={"Digestivos"} />
                    <RenderMenu titulo={"LICORES"} grupo={"Licores"} />
                    <RenderMenu titulo={"COCKTAILS"} grupo={"Cocktails"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export default Bebidas;

const RenderCapa = () => {
  return (
    <div className="list_view">
      <div className="section-title text-center center">
        <div className="menu-section">
          {capa.map((value, index) => {
            if (value !== " ") {
              return (
                <div key={index} className="menu-item">
                  <div className="menu-item-cover text-center">{value}</div>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <br />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

const RenderMenu = ({ titulo, grupo }) => {
  return (
    <div className="menu-section">
      <h2 className="menu-section-title">{titulo}</h2>
      {Drinks.map(function (item, i) {
        if (
          item.status === "Active" &&
          item.group === grupo &&
          data >= item.valini &&
          data <= item.valend
        ) {
          return (
            <div key={i} className="menu-item">
              <div className="menu-item-name">{item.title}</div>
              <div className="menu-item-price">{item.price}</div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

const RenderGroup = ({ titulo, grupo }) => {
  return (
    <div className="list_view">
      <div className="menu-section">
        <div className="section-title text-center center">
          <h2 className="menu-section-title">{titulo}</h2>
        </div>
        {Drinks.map(function (item, i) {
          if (
            item.status === "Active" &&
            item.group === grupo &&
            data >= item.valini &&
            data <= item.valend
          ) {
            return (
              <div key={i} className="menu-item">
                <div className="menu-item-name">{item.title}</div>
                <div className="menu-item-price">{item.price}</div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

// const RenderDuplo = (titulo1, grupo1, titulo2, grupo2) => {
//   return null;
// };
