import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import Lista from "../files/Vinhos.json";
import Categorias from "../files/Categorias.json";
import * as helper from "../components/helper";

const capa = [" ", " ", " ", " ", " ", " ", " ", " ", "Vinhos"];

const settings = {
  currency_code: "EUR",
  currency_symbol: "€",
  currency_format: "{amount}€",
  thousand_separator: ".",
  decimal_separator: ",",
  decimal_number: 2,
};

const ItemPrice = ({ product, settings }) => {
  if (product.regular_price !== 999.0) {
    let priceFormatted = helper.formatCurrency(product.regular_price, settings);
    return <div className="menu-item-price">{priceFormatted}</div>;
  } else {
    return null;
  }
};

const ItemName = ({ product }) => {
  var str = product.name;
  var n = str.indexOf(" ");
  var xname = str.substring(n + 1);
  if (product.regular_price !== 999.0) {
    return <div className="menu-item-name">{xname}</div>;
  } else {
    return <div className="menu-item-name">{xname}</div>;
  }
};

const ItemTab = ({ product }) => {
  if (product.regular_price !== 999.0) {
    return (
      <div>
        <div className="menu-item-tab"></div>
      </div>
    );
  } else {
    return null;
  }
};

const CatTitle = ({ title }) => {
  var str = title;
  var aux = str.search("VELHOS");
  var xname = str;
  if (aux === -1) {
    return <h2 className="menu-section-title">{xname}</h2>;
  } else {
    return (
      <div>
        <h2 className="menu-section-title">{xname}</h2>
        <h3 className="menu-section-title">* preços sob consulta</h3>
      </div>
    );
  }
};

const CategTitle = ({ category }) => {
  var str = category.name;
  var n = str.indexOf("-");
  var zname = str.substring(n + 1);
  var aux = str.search("Velhos Tintos");
  var xname = zname.toUpperCase();
  if (aux === -1) {
    return <h2 className="menu-section-title">{xname}</h2>;
  } else {
    return (
      <div>
        <h2 className="menu-section-title">{xname}</h2>
        <h3 className="menu-section-title">* preços sob consulta</h3>
      </div>
    );
  }
};

const Vinhos = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  const baseImg = <img src="img/crew-base.jpg" alt="vinhos" />;
  return (
    <div id="vinhos">
      <div className="overlay">
        <div className="section-title text-center center">
          <div className="hidden-xl hidden-lg hidden-md">
            <h2>vinhos</h2>
            <hr></hr>
          </div>
          <p>Vinhos e preços sujeitos a confirmação no estabelecimento</p>
        </div>
        <div id="carousel" className="carousel">
          <Carousel showArrows showThumbs={false} infiniteLoop emulateTouch>
            <div>
              <img src="img/crew-base.jpg" alt="vinhos" />
              <div className="legend">
                <RenderCapa />
              </div>
            </div>
            <div>
              {baseImg}
              <div className="legend">
                <RenderCategoria
                  id={"5e56ff983d8bb25a58b84616"}
                  titulo={"ALENTEJO - Vinhos Tintos"}
                />
              </div>
            </div>
            <div>
              {baseImg}
              <div className="legend">
                <RenderCategoria
                  id={"5e56ffd53d8bb25a58b84618"}
                  titulo={"DOURO - Vinhos Tintos"}
                />
              </div>
            </div>
            <div>
              {baseImg}
              <div className="legend">
                <RenderCategoria
                  id={"5e56ffbd3d8bb25a58b84617"}
                  titulo={"DOURO - Vinhos Brancos"}
                />
              </div>
            </div>
            <div>
              {baseImg}
              <div className="legend">
                <RenderCategoria
                  id={"5e57017e3d8bb25a58b8461d"}
                  titulo={"LISBOA - Vinhos Tintos"}
                />
              </div>
            </div>
            <div>
              {baseImg}
              <div className="legend">
                <RenderCategoria
                  id={"5e77932d26ee1b25848a51eb"}
                  titulo={"VINHOS VELHOS TINTOS"}
                />
              </div>
            </div>
          </Carousel>
        </div>
        <span className="visible-xs">
          <br />
          <br />
        </span>

        <span className="hidden-sm hidden-xs">
          <div className="collapse_section">
            {/* <button
              type="button"
              className="rci-button rci-toggle collapsed"
              data-toggle="collapse"
              data-target="#lista_v"
            >
              Ver em lista&nbsp;&nbsp;
              <i className="glyphicon"></i>
            </button> */}
            <br />
            <br />
          </div>
          <div id="lista_v" className="hidden-sm hidden-xs">
            <div className="section-title text-center center">
              <h2>Lista</h2>
              <hr></hr>
            </div>
            <div className="container">
              <div className="list_view">
                <RenderInList />
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export default Vinhos;

const RenderCapa = () => {
  return (
    <div className="list_view">
      <div className="section-title text-center center">
        <div className="menu-section">
          {capa.map((value, index) => {
            if (value !== " ") {
              return (
                <div key={index} className="menu-item">
                  <div className="menu-item-cover text-center">{value}</div>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <br />
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

const RenderCategoria = ({ id, titulo }) => {
  return (
    <div className="list_view">
      <div className="menu-section">
        <div className="section-title text-center center">
          <CatTitle title={titulo} />
        </div>
        {Lista.map(function (item, i) {
          if (item.enabled === true && item.category_id === id) {
            return (
              <div key={"N" + i} className="menu-item">
                <ItemName product={item} />
                {/* <span className="hidden-sm hidden-md hidden-lg hidden-xl">
                  <br />
                </span> */}
                <ItemTab product={item} />
                <ItemPrice product={item} settings={settings} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

const RenderGroup = ({ id }) => {
  return Lista.map((item, i) => {
    if (item.enabled === true && item.category_id === id) {
      return (
        <div key={i} className="menu-item">
          <ItemName product={item} />
          <span className="hidden-md hidden-lg hidden-xl">
            <br />
          </span>
          <ItemTab product={item} />
          <ItemPrice product={item} settings={settings} />
          <br />
        </div>
      );
    } else {
      return null;
    }
  });
};

const RenderRegion = ({ region }) => {
  return Categorias.map((item, i) => {
    if (item.enabled === true && item.parent_id === region.id) {
      return (
        <div key={"R" + i}>
          <div className="col-xs-12 col-sm-6">
            <CategTitle category={item} />
            <RenderGroup id={item.id} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  });
};

const RenderInList = () => {
  return Categorias.map((item, i) => {
    if (
      item.enabled === true &&
      item.parent_id === "5e4173509d54784d3001eaf1"
    ) {
      return (
        <div key={"L" + i}>
          <CategTitle category={item} />
          <div className="row">
            <RenderRegion region={item} />
          </div>
        </div>
      );
    } else {
      return null;
    }
  });
};
